
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import WirteComment from "@/views/Mine/MoreServices/Comment/WriteComment.vue";

@Component({
  components: {
    WirteComment,
  },
})
export default class ExternalWriteCommentPage extends Mixins(Mixin) {}
