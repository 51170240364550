
import { Mixin } from "../../../../core/mixins/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Icon, Image, Rate, Field, ImagePreview, Dialog } from "vant";
import uploadFile from "@/plugins/upload";
import MyNavBar from "@/components/MyNavbar.vue";

@Component({
  components: {
    Mixin,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Rate.name]: Rate,
    [Field.name]: Field,
    MyNavBar,
  },
})
export default class WriteComment extends Mixins(Mixin) {
  maxlength = 500;
  productId = 0;
  orderId = "";
  productName = "";
  form = {
    text: "",
    score: 0,
    commentImg: "",
  };
  score = 0;
  commentImgs = [] as string[];

  @Watch("commentImgs")
  changeImg() {
    this.form.commentImg = this.commentImgs.join(",");
  }
  get scoreText() {
    let str = "";
    switch (this.form.score) {
      case 1:
      case 2:
        str = "非常差";
        break;
      case 3:
      case 4:
        str = "不满意";
        break;
      case 5:
      case 6:
        str = "一般";
        break;
      case 7:
      case 8:
        str = "满意";
        break;
      case 9:
      case 10:
        str = "非常满意";
        break;
      default:
    }
    return str;
  }
  mounted() {
    this.productId = Number(this.$route.params.productId);
    this.orderId = String(this.$route.query.orderId || "");
    this.productName = String(this.$route.query.productName);
  }
  onChange(value: number) {
    this.form.score = value * 2;
  }
  selectFile(event: any) {
    const files = event.target.files;
    if (event.target.files.length > 3 - this.commentImgs.length) {
      this.$toast("您最多选择三张图片");
      return;
    }
    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i]).then((data) => {
        this.commentImgs.push(data);
      });
    }
  }
  submitComment(): void {
    if (!this.form.score) {
      this.$toast("请评分后再发布");
      return;
    }
    this.$api.memberApi.comment.comment(
      {
        productId: this.productId!,
        orderId: this.orderId,
        ...this.form,
      },
      ({ data }) => {
        if (data) {
          this.$toast({
            message: "评价成功",
            forbidClick: true,
            onClose: () => {
              if (this.AppSource == "harmonyOS") {
                this.jsBridge("back", "");
              } else {
                this.$router.go(-1);
              }
            },
          });
        }
      }
    );
  }
  previewImg(index: number) {
    ImagePreview({
      images: this.commentImgs,
      startPosition: index,
    });
  }
  deleteImg(index: number) {
    Dialog.confirm({
      message: "确认删除吗？",
    }).then(() => {
      this.commentImgs.splice(index, 1);
    });
  }
}
