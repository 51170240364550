
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import { Tabs, Tab, Col, Row, Icon, Image, Rate, List, Dialog } from "vant";
import MyNavbar from "@/components/MyNavbar.vue";
import MyNull from "@/components/MyNull.vue";

@Component({
  components: {
    Mixin,
    MyNavbar,
    MyNull,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Image.name]: Image,
    [Rate.name]: Rate,
    [List.name]: List,
    [Dialog.Component.name]: Dialog.Component,
  },
})
export default class MyCommentList extends Mixins(Mixin) {
  public waitCommentsList: Array<member.ProductCommentDTO> = [];
  public commentsList: Array<member.ProductCommentDTO> = [];
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };
  public activeName = "wait";

  created(): void {
    window.document.title = "我的评价";
    this.getMyCommentList(true);
  }
  getMyCommentList(first?: boolean): void {
    if (first === true) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    if (this.activeName === "wait") {
      this.$api.orderApi.ticketOrder.getStayCommentOrderList(
        {
          page: this.page.current,
          size: this.page.size,
        },
        ({ data }) => {
          this.list.loading = false;
          if (data) {
            if (this.page.current === 1) {
              this.waitCommentsList = data.records!;
            } else {
              this.waitCommentsList = this.waitCommentsList.concat(
                data.records!
              );
            }
            this.list.finished = data.current! >= data.pages!;
          }
        }
      );
    } else {
      this.$api.memberApi.comment.getProductComments(
        {
          pageNum: this.page.current,
          pageSize: this.page.size,
        },
        ({ data }) => {
          this.list.loading = false;
          if (data) {
            if (this.page.current === 1) {
              this.commentsList = data.records!;
            } else {
              this.commentsList = this.commentsList.concat(data.records!);
            }
            this.list.finished = data.current! >= data.pages!;
          }
        }
      );
    }
  }
  removeComment(item: member.ProductCommentDTO): void {
    Dialog.alert({
      title: "删除评价",
      message: "确定删除当前评价吗？",
      showCancelButton: true,
    })
      .then(() => {
        let params: Array<string> = [];
        params.push(item.commentId!);
        this.$api.memberApi.comment.delCommentInfoController(
          { commentIds: params },
          (data) => {
            // this.$toast(data.data);
            this.getMyCommentList(true);
          }
        );
      })
      .catch(() => {
        // on cancel
      });
  }
  likePro(item: member.ProductCommentDTO): void {
    this.$api.memberApi.comment.praise(
      { commentId: String(item.commentId) },
      (data) => {
        this.getMyCommentList(true);
      }
    );
  }
  gotoComment(item: order.ProductCommentDTO) {
    this.$router.push(
      `/write-comment/${item.productId}?productName=${item.productName}${
        item.orderId ? "&orderId=" + item.orderId : ""
      }`
    );
  }
}
