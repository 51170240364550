
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import { List } from "vant";
import CommentItem from "@/components/CommentItem.vue";
import MyNavbar from "@/components/MyNavbar.vue";
@Component({
  components: {
    Mixin,
    [List.name]: List,
    CommentItem,
    MyNavbar,
  },
})
export default class ProductCommentList extends Mixins(Mixin) {
  public productId = "";
  public businessType = "01";
  public commentsList: Array<member.ProductCommentDTO> = [];
  public list = {
    loading: false,
    finished: false,
  };
  public page = {
    current: 1,
    size: 10,
  };

  mounted() {
    if (this.$route.params.productId) {
      this.productId = String(this.$route.params.productId);

      if (this.$route.query.businessType) {
        this.businessType = String(this.$route.query.businessType);
      }
      this.getComment(true);
    }
  }
  // 获取评论
  getComment(first?: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    const productId = this.productId;
    if (productId) {
      this.$api.memberApi.comment.praiseCommentList(
        this.businessType,
        productId,
        { pageNum: this.page.current, pageSize: this.page.size },
        ({ data }) => {
          if (this.page.current === 1) {
            this.commentsList = data.records!;
          } else {
            this.commentsList = this.commentsList.concat(data.records!);
          }
          this.list.loading = false;
          this.list.finished = data.current! >= data.pages!;
        }
      );
    }
  }
}
